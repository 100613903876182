import videojs from "video.js";
import "videojs-youtube";

window.HELP_IMPROVE_VIDEOJS = false;
window.videojs = videojs;

let vslPlayer = null;
let duration = 0;
let firstPart = 0;
let secondPart = 0;
let eventStep = 0;

window.initVideos = () => {
    [...document.querySelectorAll(".video-js")].forEach((video, index) => {
        if (index == 0) {
            vslPlayer = videojs(video, {});

            vslPlayer.on("loadedmetadata", function () {
                duration = vslPlayer.duration();
            });
            vslPlayer.on("timeupdate", function () {
                let time = vslPlayer.currentTime();
                if (duration == 0) {
                    duration = vslPlayer.duration();
                }
                firstPart = duration * 0.25;
                secondPart = duration * 0.5;
                if (
                    time >= firstPart &&
                    time < firstPart + 2 &&
                    eventStep < 1 &&
                    typeof fbq != "undefined"
                ) {
                    eventStep = 1;
                    fbq(
                        "trackCustom",
                        "PlayVsl",
                        {
                            status: "25%",
                        },
                        {
                            eventID:
                                "play_vsl_one_" +
                                Math.floor(Math.random() * 10000000 + 10000000),
                        }
                    );
                }

                if (
                    time >= secondPart &&
                    time < secondPart + 2 &&
                    eventStep < 2 &&
                    typeof fbq != "undefined"
                ) {
                    eventStep = 2;
                    fbq(
                        "trackCustom",
                        "PlayVsl",
                        {
                            status: "50%",
                        },
                        {
                            eventID:
                                "play_vsl_two_" +
                                Math.floor(Math.random() * 10000000 + 10000000),
                        }
                    );
                }

                if (
                    time >= duration - 10 &&
                    time < duration &&
                    eventStep < 3 &&
                    typeof fbq != "undefined"
                ) {
                    eventStep = 3;
                    fbq(
                        "trackCustom",
                        "PlayVsl",
                        {
                            status: "100%",
                        },
                        {
                            eventID:
                                "play_vsl_three_" +
                                Math.floor(Math.random() * 10000000 + 10000000),
                        }
                    );
                }
            });
        } else {
            videojs(video, {});
        }
    });
};

document.addEventListener("livewire:init", () => {
    window.initVideos();
});
